'use client';

import { Icon, IconName } from '~/components/core/Icon';
import Flex from '~/components/customComponents/flex/Flex';
import Text from '~/components/customComponents/texts/Text';
import TitleTooltip from '~/components/customComponents/title-tooltip/TitleTooltip';
import Tooltip from '~/components/customComponents/tooltip/Tooltip';
import { EMAIL_SERVICE } from '~/constants';
import { useI18n } from '~/locales/client';
import { colors, spacing } from '~/utils/tailwindUtils';

const AlertIcon = ({ iconSize }: { iconSize: number }) => (
  <Icon
    name={IconName.Alert}
    size={iconSize}
    color={colors.black}
    customStrokeWidth={2}
  />
);

/**
 * Renders a component to show that the estimated arrival date is not available
 * - Shows a tooltip with the message
 * - Shows Alert icon next to Estimated Arrival Date text
 * @returns NoEstimatedArrivalDate component
 */
const NoEstimatedArrivalDate = ({
  etaTextClassName,
  etaText = 'general.estimated-arrival-date',
  iconSize = spacing[3.5],
}: {
  etaTextClassName?: string;
  etaText?: string;
  iconSize?: number;
}) => {
  const translate = useI18n();
  const tooltipText = translate('general.date-not-available', {
    email: EMAIL_SERVICE,
  });

  const dangeriouslySetText = (
    <span
      dangerouslySetInnerHTML={{
        __html: tooltipText,
      }}
    />
  );

  return (
    <Flex className="gap-1 md:gap-2 items-center">
      <Text className={etaTextClassName}>{translate(etaText)}</Text>
      <TitleTooltip
        tooltipText={dangeriouslySetText}
        divClassName="hidden md:inline-block"
        className="-translate-y-1/2"
      >
        <AlertIcon iconSize={iconSize} />
      </TitleTooltip>
      <Tooltip message={dangeriouslySetText} className="md:hidden">
        <AlertIcon iconSize={iconSize} />
      </Tooltip>
    </Flex>
  );
};

export default NoEstimatedArrivalDate;
