'use client';

import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Icon, IconName } from '~/components/core/Icon';
import { ColorModeEnum } from '~/utils/generalUtils';
import { colors, spacing } from '~/utils/tailwindUtils';
import Flex from '../flex/Flex';

const fadeInDuration = 1200; // Duration for the fill animation
const fadeoutDuration = 300; // Duration for the fadeout animation

/**
 * Renders a progress bar with a fill animation based on the percentage provided
 * - Client component
 * @param percentage - percentage of the progress bar to be filled
 * @param hasAnIssue - flag to indicate if there is an issue with the progress
 * @param iconSize - size of the icon to be displayed at the percentage position when there is an issue
 * @returns progress bar with a fill animation based on the percentage provided
 */
const ProgressBar = ({
  percentage = 100, // default to 100% if no percentage is provided
  hasAnIssue = false,
  iconSize = spacing[8],
}: {
  percentage?: number;
  hasAnIssue?: boolean;
  iconSize?: number;
}) => {
  const hasIssueOr100Percent = hasAnIssue || percentage === 100;
  const [isFilling, setIsFilling] = useState(hasIssueOr100Percent);
  const [width, setWidth] = useState(hasIssueOr100Percent ? percentage : 0);

  useEffect(() => {
    if (!hasIssueOr100Percent) {
      // Set the fill animation based on the percentage
      const animateProgressBar = (innerDelay: number, totalDelay: number) => {
        setTimeout(() => {
          setIsFilling(true);
          setWidth(percentage);
          setTimeout(() => {
            setIsFilling(false); // Start fadeout animation
            setTimeout(() => {
              setWidth(0); // Reset the width to 0 after fadeout
            }, fadeoutDuration);
          }, innerDelay);
        }, totalDelay);
      };

      animateProgressBar(fadeInDuration, fadeInDuration); // Initial fill animation
      animateProgressBar(
        fadeInDuration * 2,
        (fadeInDuration + fadeoutDuration) * 2
      ); // Repeat fill animation second time, thats it
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasIssueOr100Percent) {
      return;
    }
    if (percentage === 0) {
      setIsFilling(false);
      return;
    }

    // Set the fill animation based on the percentage
    const interval = setInterval(() => {
      setIsFilling(true); // set the filling state to true to start the fill animation
      setWidth(percentage); // Set the width to the percentage value to fill the progress bar
      setTimeout(() => {
        setIsFilling(false); // set the filling state to false to start the fadeout animation
        setTimeout(() => {
          setWidth(0); // Set the width to 0 to fadeout the progress bar
        }, fadeoutDuration);
      }, fadeInDuration * 2);
    }, (fadeInDuration + fadeoutDuration) * 2);

    return () => clearInterval(interval);
  }, [percentage, hasAnIssue, hasIssueOr100Percent]);

  return (
    <div className="relative h-2 flex-1 rounded-full bg-gray-200">
      <div
        className={twMerge(
          'h-full rounded-full w-0 transition-all ',
          isFilling
            ? `bg-red-600 opacity-100 duration-${fadeInDuration * 0.5}`
            : `bg-gray-600 opacity-0 duration-${fadeoutDuration}`
        )}
        style={{ width: `${width}%` }}
        role="progressbar"
      />
      {/* Conditionally render the icon at the percentage position if there is an issue */}
      {hasAnIssue && (
        <Flex
          className="absolute" // Adjust positioning based on the icon size and styling needs
          style={{
            left: `${percentage}%`,
            transform: 'translateX(-50%) translateY(-60%)',
          }}
        >
          <Icon
            name={IconName.MinusCircleNotAllowed}
            size={iconSize}
            color={colors.secondary}
            colorMode={ColorModeEnum.Fill}
          />
        </Flex>
      )}
    </div>
  );
};

export default ProgressBar;
